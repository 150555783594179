.marquee {
    position: absolute;
    top: 50%;
    left: 60px;/*left padding because it is preventing menu collapse button*/
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.marquee span {
    display: inline-block;
    padding-left: 100%; /* Start with text off-screen */
    animation: marquee-text 20s linear infinite;
}

@keyframes marquee-text {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%); /* Move by text width */
    }
}

.main {
    font-size: 32px;
    background: linear-gradient(to right, green, blue, red);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    font-family: 'Courier New', monospace;
}
.tiny{
  font-size:12px;
  color:#4585df;
}






