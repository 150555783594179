/* Bingo Container Styles */
.bingo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Bingo Header Styles */
  .bingo-header {
    display: flex;
    background-color: #020c3a; /* Header background color */
    color: #fff; /* Header text color */
    font-weight: bold;
    border-radius: 8px 8px 0 0;
  }
  
  .header-cell {
    flex: 1;
    text-align: center;
    padding: 10px;
  }
  
  /* Bingo Grid Styles */
  .bingo-grid {
    display: flex;
    width: max-content; /* Set the width to the content size */
  }
  
  /* Bingo Column Styles */
  .bingo-column {
    display: flex;
    flex-direction: column;
  }
  
  /* Bingo Cell Styles */
  .cell {
    flex: 1;
    text-align: center;
    padding: 32px;
    margin: 4px; /* Add a margin for the gap between cells */
    border: 1px solid #020c3a; /* Cell border color */
    cursor: pointer;
    /*transition: background-color 0.1s ease; /* Smooth transition for background color */
    border-radius: 8px; /* Round corners for square cells */
    
  }
  
  /*.cell:hover {
    background-color: #f0f0f0; /* Hover background color 
  }*/
  
  .selected {
    background-color: #020c3a; /* Selected cell background color */
    /*border-radius: 80%; /* Make selected cell circular */
  }
  
  /* Free Space Styles */
  /*.free-space {
    font-weight: bold;
    white-space: nowrap; /* Prevent wrapping of text 
    overflow: hidden; /* Hide overflow if the text is too long 
    text-overflow: ellipsis; /* Display an ellipsis (...) if text overflows 
  }*/
  