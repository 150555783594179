/* Game Controls */
.jackpot-game-controls {
  width: 100%;
  max-width: 150px; /* Reduced from 300px */
  margin: 0 0;
}

.jackpot-ball-display {
  width: 75%;
  padding-bottom: 75%;
  position: relative;
  border-radius: 50%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4), 0 0 15px rgba(255, 255, 255, 0.3); /* Added a stronger outer glow */
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)); /* Shiny highlight */
  animation: spin 5s infinite linear; /* Subtle spin effect */
}

.jackpot-ball-display .jackpot-content {
  position: absolute;
  top: 25%;
  left: 25%;
  right: 25%;
  bottom: 25%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: radial-gradient(circle at 30% 30%, white, #efefef);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset -0.25rem -0.25rem 1rem 0 rgba(0, 0, 0, 0.16),
              0 0 0 0.125rem white,
              inset 0.25rem 0.25rem 0.75rem 0.125rem rgba(255, 255, 255, 0.3);
  border: 0.125rem solid rgba(255, 255, 255, 0.4);
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.8)); /* Added a white glow */
}

.jackpot-ball-display .jackpot-content .jackpot-ball-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); /* Added text shadow for a 3D effect */
}

.jackpot-ball-display .jackpot-content .jackpot-ball-content .jackpot-ball-letter,
.jackpot-ball-display .jackpot-content .jackpot-ball-content .jackpot-ball-number {
  line-height: 1;
  text-align: center;
}

.jackpot-ball-display .jackpot-content .jackpot-ball-content .jackpot-ball-letter {
  font-size: 3.5rem; /* Increased size slightly */
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.jackpot-ball-display .jackpot-content .jackpot-ball-content .jackpot-ball-number {
  font-size: 3.5rem; /* Increased size slightly */
  font-weight: bold;
}

/* Color variations with added shiny effects */
.jackpot-ball-display.blue {
  background: radial-gradient(circle at 30% 30%, #4a4aff, #0000ad);
  filter: drop-shadow(0 0 15px rgba(0, 0, 255, 0.7)); /* Blue glow */
}

.jackpot-ball-display.red {
  background: radial-gradient(circle at 30% 30%, #ff4a4a, #910000);
  filter: drop-shadow(0 0 15px rgba(255, 0, 0, 0.7)); /* Red glow */
}

.jackpot-ball-display.white {
  background: radial-gradient(circle at 30% 30%, #ffffff, #b6b6b6);
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.7)); /* White glow */
}

.jackpot-ball-display.green {
  background: radial-gradient(circle at 30% 30%, #4aff4a, #004a00);
  filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.7)); /* Green glow */
}

.jackpot-ball-display.yellow {
  background: radial-gradient(circle at 30% 30%, #ffff4a, #f7bd00);
  filter: drop-shadow(0 0 15px rgba(255, 255, 0, 0.7)); /* Yellow glow */
}

/* Colored border for the inner white circle */
.jackpot-ball-display.blue .jackpot-content { border: 0.125rem solid #0000FF; }
.jackpot-ball-display.red .jackpot-content { border: 0.125rem solid #FF0000; }
.jackpot-ball-display.white .jackpot-content { border: 0.125rem solid #666666; }
.jackpot-ball-display.green .jackpot-content { border: 0.125rem solid #008000; }
.jackpot-ball-display.yellow .jackpot-content { border: 0.125rem solid #f7bd00; }

/* Animation for subtle spinning effect */
/* @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

@keyframes spin {
  0% {
    transform: scale(1); /* Start at normal size */
  }
  30% {
    transform: scale(0.8); /* Scale down for the "beat" */
  }
  50% {
    transform: scale(1); /* Return to normal size */
  }
  70% {
    transform: scale(0.8); /* Second "beat" */
  }
  100% {
    transform: scale(1); /* Back to original size */
  }
}
