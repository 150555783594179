/* Animations */
@keyframes blink {
  0% {
    color: white;
  }
  50% {
    color: #3c3c3c;
  }
  100% {
    color: white;
  }
}

@keyframes bounce {
  0% {
    bottom: 2rem;
  }
  50% {
    bottom: 2.5rem;
  }
  100% {
    bottom: 2rem;
  }
}