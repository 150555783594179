
.board-container {
    display: flex;
    align-items: center;
    justify-content: center;
    
    height:100%;
    width:100%;
    gap: 10px 10px;
    flex-wrap: wrap;
    padding: 25px;


}


.card-display {
    padding: 23px;
    cursor: pointer;
    border-radius: 5%;
    width: 30%;
    min-width: 160px;
    max-width: 300px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    background-color: rgb(9, 14, 44);
    box-shadow: inset 0.5rem 0.5rem 1.5rem 0.25rem rgb(255 255 255 / 30%), inset -0.25rem -0.25rem 1.5rem 0.5rem rgb(0 0 0 / 40%);
}


.card-content {
    text-align: center;
    border-radius: 100%;
    background: white;
    position: relative;
    min-width: 60px;
    min-width: 3vw;
    padding: 28%;
    box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(255, 255, 255, 0.16), 0 0 0 0.25rem white;
    background: radial-gradient(circle at 20% 20%, rgb(255, 255, 255), white);
}

.card-detail-content {
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    flex-direction: column;
}

.card-selected {
    background-color:green;
    border-radius: 100%;
    color: white;
}

.card-id {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    display: block;
    width: 100%;
}

.card-name {
    line-height: 1;
    display: block;
    width: 100%;
    font-weight: bold;
}