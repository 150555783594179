.bingo-board {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2px; /* Reduced gap to make the board narrower */
    max-width: 200px; /* Narrower width */
    margin: auto;
  }
  
  .bingo-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px; /* Narrower cells */
    height: 40px; /* Narrower cells */
    border: 1px solid #000;
    background-color: transparent;
    /* transition: background-color 0.3s ease; */
    font-size: 1.2rem; /* Adjusted font size to fit smaller cells */
    border-radius: 5px;
  }
  
  .pattern-one {
    animation: blinkPatternOne 3s infinite;
  }
  
  .pattern-two {
    animation: blinkPatternTwo 3s infinite;
    animation-delay: 3s; /* Delay the start of the second animation */
  }
  
  .pattern-both {
    animation: blinkPatternBoth 3s infinite; /* Overlapping action */
  }
  
  
  @keyframes blinkPatternOne {
    0%, 100% { background-color: transparent; }
    50% { background-color: rgb(1, 5, 27); } /* Yellow color for pattern one */
  }
  
  @keyframes blinkPatternTwo {
    0%, 100% { background-color: transparent; }
    50% { background-color: #390352; } /* Green color for pattern two */
  }
  
  @keyframes blinkPatternBoth {
    0%,100% { background-color: transparent; }
    50% { background-color: rgb(1, 5, 27); } /* Yellow color for pattern one */
     /* Green color for pattern two */
  }
  
  
  